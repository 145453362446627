@import 'styles/config';

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.h5 {
  @include h5;
}

.h6 {
  @include h6;
}

@media (hover: hover) {
  .gradientTitle {
    position: relative;

    background: radial-gradient(
      circle at var(--x, 50%) var(--y, 50%),
      #320564,
      #320564
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover {
      overflow: visible;
      cursor: default;
      background: radial-gradient(
        circle at var(--x, 50%) var(--y, 50%),
        #00c38b,
        #320564,
        #320564,
        #320564,
        #320564
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
